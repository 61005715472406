import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button } from '@mui/material';


const apiurl = process.env.REACT_APP_API_URL;

function CandidateTestList() {
  const [candidateTests, setCandidateTests] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCandidateTestId, setSelectedCandidateTestId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiurl + '/api/candidate-tests/', {
          headers: {
            Authorization: `Token ${localStorage.getItem('authKey')}`,
          },
        });
        const sortedCandidateTests = response.data.sort(
          (a, b) => new Date(b.start_time) - new Date(a.start_time)
        );
        const candidateTestsWithId = sortedCandidateTests.map((test, index) => ({
          id: index + 1,
          ...test,
        }));
        setCandidateTests(candidateTestsWithId);
      } catch (error) {
        console.error('Error fetching candidate tests:', error);
      }
    };

    fetchData();
  }, []);

  const handleDetailsClick = (rowData) => {
    if (rowData.candidate_id) {
      const candidateId = rowData.candidate_id;
      const url = `/admin/dash/candidateprofile/${candidateId}`;
      window.open(url, '_blank');
    } else {
      setSelectedCandidateTestId(rowData.id);
      setOpenModal(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'candidate_id', headerName: 'Candidate ID', width: 200 },
    { field: 'candidate_email', headerName: 'Candidate Email', width: 200 },
    { field: 'test_name', headerName: 'Test Name', width: 200 },
    { field: 'start_time', headerName: 'Start Time', width: 200 },
    {
      field: 'details',
      headerName: 'Details',
      width: 150,
      renderCell: (params) => (
        params.row.candidate_id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDetailsClick(params.row)}
          >
            View Details
          </Button>
        ) : (
          <Button variant="contained" color="primary" disabled>
            Answer Sheet
          </Button>
        )
      ),
    },
  ];

  return (
    <div>
      <h2>Candidate Tests</h2>
      <div style={{ height: 750, width: '100%', marginTop: '20px' }}>
        <DataGrid
          rows={candidateTests}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          
        />
      </div>

    </div>
  );
}

export default CandidateTestList;
