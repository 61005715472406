import React from 'react';
import ReactDOM from 'react-dom';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from './admin/AdminDashboard';
import AdminLogin from './admin/AdminLogin';
import UserLogin1 from './User/UserLogin1';
import UserTest from './User/UserTest';
import UserPostmsg from './User/UserPostmsg';
import UserCompleted from './User/UserCompleted';
import UserRegistrationPage from './User/UserRegistration';
import Firebasecheck from './User/firebasecheck';
import Uservideo from './User/UserVideo';
import AdminUserLists from './admin/AdminUserList';
import Newdash from './admin/dashboard/Dashboard';
import DashCandidates from './admin/dashboard/Candidates';
import DashCandidatesList from './admin/dashboard/CandidatesTest';
import CandidateReview from './User/UserRating';
import SqlEditor from './User/MonacoEditor';
import ProtectedRoute from './admin/utils/ProtectedRoute';
import CandidateFilterWip from './admin/dashboard/CandidateFilterwip1';
import CandidateDashboard from './admin/dashboard/CandidateDashboard';
import CandidateProfileListing from './admin/dashboard/CandidateProfileListing';
import CandidateProfileDetail from './admin/dashboard/CandidateProfileDetail';
import CandidateProfileListingDatagrid from './admin/dashboard/CandidateProfileListingDatagrid';
import NotFoundPage  from './User/NotFoundPage';
import HrLogin from './admin/HrLogin';
import JobStatusTable from './JobStatusTable';
import HRManagerForm from './HRmanager';
import HRManagerAccessList from './HRmanagerAccessList';
import CandidateTestList from './admin/dashboard/CandidateTestList';
import StaffMonitor from './admin/dashboard/StaffWorkProgress';
import SankeyChart from './admin/dashboard/StaffDropoff';
import SharedCandidatesView from './admin/dashboard/ShareCandidateView';
//From Vysakh//


import CandidateForm from './components/CandidateForm';
import ThankYouPage from './components/ThankYouPage';
import JupyterNotebookComponent from './User/JupyterNotebookComponent';
import UserPremsg2 from './User/UserPremsg2';










function App() {
  return (
    <>
    <Routes>   
    {/* <Route path ="/" element={<UserLogin1 />} /> */}
    <Route path ="/" element={<ThankYouPage />} />


    <Route path ="admindash" element={<AdminDashboard />} />
    
    <Route path ="user2" element={<UserLogin1 />} />
    <Route path ="user" element={<UserLogin1 />} />
    <Route path ="usertest" element={<UserTest />} />
    <Route path ="jupyter" element={<JupyterNotebookComponent />} />
    <Route path ="sql" element={<SqlEditor />} />
    <Route path ="usermsg" element={<UserPremsg2 />} />
    <Route path ="finish" element={<UserPostmsg />} />
    <Route path ="completed" element={<UserCompleted />} />
    <Route path ="register" element={<UserRegistrationPage />} />
    <Route path ="firebasetest" element={<Firebasecheck />} />
    <Route path ="adminheink" element={<AdminLogin />} />
    <Route path="/:jobPost/apply" element={<CandidateForm />} />
    <Route path="/thank-you" element={<ThankYouPage />} />
    <Route path="/video" element={<Uservideo />} />
    <Route path="/admin/users" element={<AdminUserLists />} />
    <Route path="newdash" element={<ProtectedRoute><Newdash /></ProtectedRoute>} />
    <Route path="/admin/dash/candidates" element={<ProtectedRoute><DashCandidates /></ProtectedRoute>} />
    <Route path="/admin/dash/candidatestests" element={<ProtectedRoute><DashCandidatesList /></ProtectedRoute>} />
    <Route path ="/:testName" element={<UserLogin1 />} />
    <Route path="/review" element={<CandidateReview />} />   
    <Route path="/admin/dash/filter" element={<ProtectedRoute><CandidateFilterWip/></ProtectedRoute>} />
    <Route path="/admin/dash/candidatedashboard" element={<ProtectedRoute><CandidateDashboard/></ProtectedRoute>} />
    <Route path="/admin/dash/candidatelisting" element={<ProtectedRoute><CandidateProfileListing/></ProtectedRoute>} />
    <Route path="/admin/dash/candidateprofile/:candidateId" element={<CandidateProfileDetail/>} />
    <Route path="/admin/dash/candidateprofilegrid" element={<ProtectedRoute><CandidateProfileListingDatagrid/></ProtectedRoute>} />
    <Route path="/admin/dash/candidatetests" element={<ProtectedRoute><CandidateTestList/></ProtectedRoute>} />
    <Route path="/staff/:days/:updateduser_ids" element={<StaffMonitor/>} />
    <Route path="/dropoffs/:updatedUserId" element={<SankeyChart/>} />


    
    <Route path ="adminhr" element={<HrLogin />} />
    <Route path="jobstatus" element={<JobStatusTable />} />
    <Route path="hradmin" element={<HRManagerForm/>}/>
    <Route path="access" element={<HRManagerAccessList/>}/>
    
    <Route path="/shared-candidates/:candidateIds" element={<SharedCandidatesView />} />
    





  






    {/*<Route path="*" element={<NotFoundPage />} />*/} {/* Catch-all route for 404 */}

    </Routes>
    </>
  );
}

export default App;