import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';

const SharedCandidatesView = () => {
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { candidateIds } = useParams();

  useEffect(() => {
    const fetchSharedCandidates = async () => {
      try {
        setIsLoading(true);
        const ids = candidateIds.split(',');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shared-candidates/?ids=${ids.join(',')}`);
        
        // Transform the data to include question answers
        const transformedData = response.data.map(candidate => {
          const questionFields = candidate.questions.reduce((acc, question) => {
            acc[question.question_text] = question.answer_text;
            return acc;
          }, {});
          
          return {
            ...candidate,
            ...questionFields
          };
        });
        
        setCandidates(transformedData);
      } catch (error) {
        console.error('Error fetching shared candidates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (candidateIds) {
      fetchSharedCandidates();
    }
  }, [candidateIds]);

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone_number', headerName: 'Phone Number', width: 130 },
    { 
      field: 'current_salary', 
      headerName: 'Current CTC', 
      width: 130,
      type: 'number',
      valueFormatter: (params) => params.value ? `${params.value}` : 'N/A'
    },
    { 
      field: 'expected_salary', 
      headerName: 'Expected CTC', 
      width: 130,
      type: 'number',
      valueFormatter: (params) => params.value ? `${params.value}` : 'N/A'
    },
    { 
      field: 'notice_period_length', 
      headerName: 'Notice Period', 
      width: 130,
      valueFormatter: (params) => params.value ? `${params.value} days` : 'N/A'
    },
    { 
      field: 'total_years_exp', 
      headerName: 'Experience', 
      width: 130,
      type: 'number',
      valueFormatter: (params) => params.value ? `${params.value} years` : 'N/A'
    },
    { field: 'user_current_status', headerName: 'Status', width: 150 },
    { field: 'job_posting_title', headerName: 'Job Position', width: 200 },
    { 
      field: 'applied_on', 
      headerName: 'Applied Date', 
      width: 180,
      type: 'date',
      valueFormatter: (params) => params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'resume',
      headerName: 'Resume',
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value) {
          // Ensure the URL includes the REACT_APP_API_URL as the base
          const baseUrl = process.env.REACT_APP_API_URL;
          const resumeUrl = params.value.startsWith('http')
            ? params.value
            : `${baseUrl}${params.value}`;

          return (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => window.open(resumeUrl, '_blank')}
              style={{ textTransform: 'capitalize', fontSize: '12px' }}
            >
              View
            </Button>
          );
        } else {
          // Render placeholder if there's no resume URL
          return <span>No Resume</span>;
        }
      },
    },
    { 
        field: 'comments', 
        headerName: 'Comments', 
        width: 200,
        flex: 1 
      }
  ];

  return (
    <div className="m-4 bg-white rounded-lg shadow-md">
      <div className="p-4 border-b">
        <h2 className="text-2xl font-bold">Candidate Profiles</h2>
      </div>
      <div className="p-4">
        <DataGridPro
          rows={candidates}
          columns={columns}
          loading={isLoading}
          pageSizeOptions={[10, 25, 50]}
          initialState={{
            pagination: { pageSize: 10 },
            sorting: { sortModel: [{ field: 'applied_on', sort: 'desc' }] },
          }}
          autoHeight
          getRowId={(row) => row.id}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
              padding: '8px',
            },
          }}
        />
      </div>
    </div>
  );
};

export default SharedCandidatesView;
